import { ArticleList } from '../../../../components/ArticleList/ArticleList';
import { Col, Grid, Row, Section } from '../../../../theme/components';
import { Heading } from '../../../../theme/components/Typography';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const Articles = props => {
  const { articles, noResultsMessage } = props;

  return isEmpty(articles) ? (
    <Section mb={{ md: 6, xs: 3 }} mediumPadding>
      <Grid>
        <Row>
          <Col>
            <Heading h3 n04 semibold>
              {noResultsMessage}
            </Heading>
          </Col>
        </Row>
      </Grid>
    </Section>
  ) : (
    <Section mb={{ md: -10, xs: -5 }} mediumPadding relative zIndex={10}>
      <ArticleList articles={articles} />
    </Section>
  );
};

Articles.propTypes = {
  articles: PropTypes.array,
  noResultsMessage: PropTypes.string
};
