import { Col, Grid, Row, Section } from '../../../../theme/components';
import { SectionContent } from '../../../../components';
import { colors, fonts } from '../../../../theme';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const placeholderStyle = css`
  color: ${colors.n05};
`;

const Input = styled.input`
  background-color: ${colors.n02};
  border: 0;
  border-radius: 0.1875rem;
  color: ${colors.n08};
  font-family: ${fonts.families.sans};
  font-size: ${fonts.size('base')};
  height: 3.375rem;
  outline: 0;
  padding: 0 1rem;
  width: 100%;

  ::placeholder,
  ::-webkit-input-placeholder {
    ${placeholderStyle};
  }
  :-ms-input-placeholder {
    ${placeholderStyle};
  }
`;

export const Hero = props => {
  const { handleInputChange, heading, inputPlaceholder } = props;

  return (
    <Section mediumPadding mt={1.5}>
      <Grid>
        <Row>
          <Col md={5} xs={12}>
            <SectionContent heading={heading} />
          </Col>
        </Row>
        <Row>
          <Col md={6} mt={3.5} xs={12}>
            <Input onChange={event => handleInputChange(get(event, 'target.value'))} placeholder={inputPlaceholder} />
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

Hero.propTypes = {
  handleInputChange: PropTypes.func,
  heading: PropTypes.string,
  inputPlaceholder: PropTypes.string
};
