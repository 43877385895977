import { Articles, Hero } from './Components';
import { debounce, filter } from 'lodash';
import { extractFromCamelCase } from 'prismic-react-tools';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import withPrismicPage from '../../HOC/withPrismicPage';

const filterArticles = (articlesArray, searchString) => {
  if (!searchString) {
    return articlesArray;
  }

  const articles = filter(articlesArray, article => {
    const { description, keywords, uid, title } = article;

    return (
      (!!uid && uid.toLowerCase().includes(searchString.toLowerCase())) ||
      (!!title && title.toLowerCase().includes(searchString.toLowerCase())) ||
      (!!description && description.toLowerCase().includes(searchString.toLowerCase())) ||
      (!!keywords && keywords.toLowerCase().includes(searchString.toLowerCase()))
    );
  });

  return articles;
};

const News = props => {
  const [articles, setArticles] = useState([]);

  const {
    pageContext: { articles: articlesFromPrismic, document }
  } = props;

  const sortArticles = searchString => {
    const articles = filterArticles(articlesFromPrismic, searchString);

    setArticles(articles);
  };

  useEffect(() => setArticles(articlesFromPrismic), []);

  const handleInputChange = debounce(value => sortArticles(value), 500);

  const { noResultsMessage } = extractFromCamelCase(document, 'hero') || {};

  const hero = {
    ...extractFromCamelCase(document, 'hero'),
    handleInputChange
  };

  return (
    <>
      <Hero {...hero} />
      <Articles articles={articles} noResultsMessage={noResultsMessage} />
    </>
  );
};

News.propTypes = {
  pageContext: PropTypes.object
};

export default withPrismicPage(News);
